@import "../styles/variables.module";

.container {
  padding: 1.6rem 1.6rem;
  margin: 0 auto;

  @include media(">desktop720p") {
    padding: 1.6rem 3.4rem 0;
  }
  @include media("<=tablet") {
    padding: 0 1.6rem;
  }
}

.inner-container {
  max-width: $content-max-width;
  margin: 0 auto;
}

.home_stations_wrapper {
  margin-bottom: 0.6rem;
}
.home_podcasts_wrapper {
  margin-bottom: 3rem;
}
