@import "../../../styles/variables.module";

body:global(.low-performance) {
  .wall_item,
  .wall_item:before,
  .wall_cover {
    transition: none !important;
  }
  .placeholder_item {
    &:before {
      display: none;
    }
  }
}

body:global(.arrow-navigation) {
  .wall_item {
    &:focus {
      @include media(">phoneXL") {
        transform: scale(1.2);
        z-index: $z-index-base;
      }
    }
  }
}

.wrapper {
  .header {
    display: flex;
    justify-content: space-between;
    font-size: 3.2rem;
    margin-bottom: 0.8rem;
    color: $color-light;
    position: relative;
    z-index: $z-index-base;

    @include media(">tablet") {
      padding-right: 1.6rem;
      padding: 0 0 0 1.6rem;
    }
    @include media("<=phoneXL") {
      padding: 0;
    }

    h2 {
      font-size: 3.2rem;
      color: $color-light;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      @include media("<=tablet") {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }

    .buttons {
      flex-shrink: 0;
      direction: ltr;
    }

    .arrow {
      &:first-child {
        margin-right: 1.6rem;
      }
      svg {
        width: 4rem;
        height: 4rem;
      }
      &.arrow_back {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.wall_wrapper {
  padding: 1.6rem 0;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0 -1.6rem;

  @include media(">desktop720p") {
    margin: 0 -3.4rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.wall_row {
  display: flex;

  .wall_item {
    transition: 0.5s all;
    cursor: pointer;

    .wall_cover {
      box-shadow: inset 0 0 10px 7px $color-light-transparent-15;
      transition: 0.5s all;
    }

    &:hover {
      @include media(">phoneXL") {
        transform: scale(1.2);
        z-index: $z-index-base;
      }
    }
  }
}

.loader {
  height: 46rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 9.2rem;
    height: 9.2rem;
  }
}
.placeholder_item {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  width: 15.6rem;
  height: 15.6rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 15.6rem;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(#e8e8e8, 0.2) 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
