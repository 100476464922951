@import "../../../styles/variables.module";

$category-width: 22rem;
$category-padding: 1.6rem;

.wrapper {
  --category-width: 22rem;
  --category-height: 11.6rem;
}

body:global(.car-mode) {
  .wrapper {
    --category-width: 38rem;
    --category-height: 20rem;
  }
}

.header {
  font-size: 3.2rem;
  color: $color-light;
  padding-left: $base-padding;
  display: flex;
  justify-content: space-between;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @include media("<=tablet") {
    padding-left: 0;
  }

  .premium_label {
    display: inline-block;
    color: #ffca41;
    font-style: normal;
    font-size: 1.7rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    position: relative;
    top: -1.2rem;
    margin-left: 1.2rem;

    @include media("<tablet") {
      font-size: 1rem;
    }
  }

  .buttons {
    flex-shrink: 0;
    direction: ltr;
  }

  span {
    @include media("<tablet") {
      font-size: 2.4rem;
      line-height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .arrow {
    position: relative;
    z-index: 1;
    &:first-child {
      margin-right: 1.6rem;
    }
    svg {
      width: 4rem;
      height: 4rem;
    }
    &.arrow_back {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

body:global(.arrow-navigation) {
  .category:focus {
    transform: scale(1.1);
    object-fit: cover;
    outline: none;
  }
}

body:global(.low-performance) {
  .category {
    transition: none !important;
  }
}

.category_list {
  display: flex;
  overflow-x: auto;
  margin: 0.8rem -3.4rem 3.2rem;
  padding: 0.8rem 3.4rem;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: "";
    flex: 0 0 calc(100vw - var(--category-width) - 6.4rem - 4.2rem);

    @include media("<=phoneXL") {
      flex: 0 0 calc(100vw - var(--category-width) - 0.8rem);
    }
  }

  @include media("<=tablet") {
    padding: 0;
    margin: 0.8rem (-1 * $category-padding) 3.2rem 0;
  }
  @include media("<=desktop720p") {
    margin-right: -$category-padding;
  }

  .category {
    cursor: pointer;
    padding: 0 $category-padding;
    transition: 0.5s all;

    &.active,
    &:hover {
      transform: scale(1.1);
      object-fit: cover;
    }

    @include media("<=tablet") {
      padding: 0;
      padding-right: 3.2rem;
    }
  }

  .category_img {
    width: 100%;
    height: var(--category-height);
    margin-bottom: 0.8rem;
    box-shadow: inset 0 0 10px 7px $color-light-transparent-15;
    border-radius: 8px;

    .cover_empty {
      width: var(--category-width);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 8px;
      background-color: rgba($color-gray, 0.05);
      img {
        width: 80%;
      }
    }
    .cover_wrapper {
      position: relative;
      width: var(--category-width);
      height: var(--category-height);
    }
    .cover {
      border-radius: 8px;
    }
  }

  .category_details {
    color: white;
  }

  .category_name {
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.5rem;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }
}

.placeholder_item {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  border-radius: 4px;
  width: var(--category-width);
  height: var(--category-height);
  position: relative;
  overflow: hidden;
  flex-shrink: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: var(--category-width);
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(#e8e8e8, 0.2) 50%,
      transparent 100%
    );
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}

:export {
  categoryOuterWidth: $category-width + (2 * $category-padding);
}
